<template>
  <v-app>
    <router-view />
    <Footer />
  </v-app>
</template>

<script>

export default {
  name: "App",
};
</script>
