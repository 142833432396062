import axios from "axios";
window._ = require('lodash');
window.axios = require('axios');

axios.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('user-token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
           
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);