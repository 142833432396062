import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,

  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      name: "Login",
      path: "/login",
      // redirect: "/authentication/login",
      component: () => import("@/views/Auth/Login"),
    },

    {
      name: "Register",
      path: "/register",
      // redirect: "/authentication/login",
      component: () => import("@/views/Auth/Register"),
    },

    {
      path: "/administrator/",
      redirect: "/administrator/dashboard",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        // Administrator
        {
          name: "Dashboard",
          path: "/administrator/dashboard",
          component: () => import("@/views/Administrator/Dashboard/Dashboard"),
        },

        {
          name: "Department",
          path: "/administrator/departments",
          component: () => import("@/views/Departments/Departments"),
        },

        {
          name: "Payment",
          path: "/administrator/payments",
          component: () => import("@/views/Payments/Payments"),
        },

        {
          name: "Category",
          path: "/administrator/categories",
          component: () => import("@/views/Administrator/Category"),
        },

        {
          name: "Profiles",
          path: "/administrator/profiles",
          component: () => import("@/views/Profile/Profile"),
        },

        {
          name: "Project",
          path: "/administrator/projects",
          component: () => import("@/views/Projects/Projects"),
        },

        {
          name: "Product",
          path: "/administrator/products",
          component: () => import("@/views/Administrator/Product/Product"),
        },

        {
          name: "ProductCategory",
          path: "/administrator/product-categories",
          component: () =>
            import("@/views/Administrator/Product/ProductCategory"),
        },

        {
          name: "ProductVariant",
          path: "/administrator/product-variants",
          component: () =>
            import("@/views/Administrator/Product/ProductVariant"),
        },

        // {
        //   name: "category",
        //   path: "/administrator/product-category",
        //   component: () => import("@/views/Administrator/Product/ProductCategory"),
        // },

        {
          name: "Calendar",
          path: "/administrator/project/calendar",
          component: () => import("@/views/Administrator/Calendar"),
        },

        {
          name: "CarProfile",
          path: "/administrator/car-profile",
          component: () => import("@/views/Cars/Cars"),
        },

        {
          name: "Gant Chart",
          path: "/administrator/project/chart",
          component: () => import("@/views/Administrator/GanntChart"),
        },

        {
          name: "Timeline",
          path: "/administrator/project/timeline",
          component: () => import("@/views/Administrator/Timeline"),
        },

        {
          name: "Print",
          path: "/project/proposed-work-list",
          component: () => import("@/views/Administrator/PrintWorkList"),
        },

        {
          name: "Worker",
          path: "/administrator/workers",
          component: () => import("@/views/Workers/Workers"),
        },

        {
          name: "Tasks",
          path: "/administrator/tasks",
          component: () => import("@/views/Tasks/Tasks"),
        },

        {
          name: "Worklist",
          path: "/administrator/work-list",
          component: () => import("@/views/Administrator/Worklist"),
        },

        {
          name: "Supplier",
          path: "/administrator/suppliers",
          component: () => import("@/views/Suppliers/Suppliers"),
        },

        {
          name: "Auto Parts",
          path: "/administrator/auto-parts",
          component: () => import("@/views/AutoParts/AutoParts"),
        },

        {
          name: "admin-events",
          path: "/administrator/events",
          component: () => import("@/views/Events/AdminEvent"),
        },

        {
          name: "admin-bodykit",
          path: "/administrator/bodykits",
          component: () => import("@/views/BodyKits/AdminBodyKit"),
        },

        {
          name: "admin-bodykit-parts",
          path: "/administrator/bodykits/:id",
          component: () => import("@/views/BodyKits/View/AdminBodykitParts"),
        },

        {
          name: "admin-brands",
          path: "/administrator/brands",
          component: () => import("@/views/Brand/AdminBrand"),
        },

        {
          name: "admin-services",
          path: "/administrator/services",
          component: () => import("@/views/Services/AdminServices"),
        },

        {
          name: "admin-cars",
          path: "/administrator/cars",
          component: () => import("@/views/Cars/AdminCars"),
        },

        {
          name: "admin-parts",
          path: "/administrator/parts",
          component: () => import("@/views/Parts/AdminParts"),
        },

        {
          name: "admin-gallery",
          path: "/administrator/gallery",
          component: () => import("@/views/Gallery/AdminGallery"),
        },

        {
          path: "*",
          component: () => import("@/views/PageNotFound.vue"),
        },

        // {
        //   name: "Delivery",
        //   path: "/administrator/delivery",
        //   component: () => import("@/views/Deliveries/Deliveries"),
        // },
      ],
    },

    // {
    //   path: "/department/",
    //   redirect: "/department/dashboard",
    //   component: () => import("@/layouts/full-layout/Layout"),
    //   children: [
    //     {
    //       name: "Dashboard",
    //       path: "/department/dashboard",
    //       component: () => import("@/views/Department/Dashboard"),
    //     },
    //     {
    //       name: "Category",
    //       path: "/department/categories",
    //       component: () => import("@/views/Department/Category"),
    //     },
    //     {
    //       name: "Worker",
    //       path: "/department/workers",
    //       component: () => import("@/views/Department/Worker"),
    //     },
    //     {
    //       name: "Work-List",
    //       path: "/department/work-lists",
    //       component: () => import("@/views/Department/Worklist"),
    //     },
    //     {
    //       name: "Calendar",
    //       path: "/department/project/calendar",
    //       component: () => import("@/views/Department/Calendar"),
    //     },
    //     {
    //       name: "Calendar",
    //       path: "/department/project/timeline",
    //       component: () => import("@/views/Department/Timeline"),
    //     },
    //     {
    //       name: "Project",
    //       path: "/department/projects",
    //       component: () => import("@/views/Department/Project"),
    //     },
    //   ]
    // },

    // {
    //   path: "/worker/",
    //   redirect: "/worker/dashboard",
    //   component: () => import("@/layouts/full-layout/Layout"),
    //   children: [
    //     {
    //       name: "Dashboard",
    //       path: "/worker/dashboard",
    //       component: () => import("@/views/Worker/Dashboard"),
    //     },
    //     {
    //       name: "Category",
    //       path: "/worker/work-lists",
    //       component: () => import("@/views/Worker/Worklist"),
    //     },
    //   ]
    // }
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
