import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
})

export default new Vuex.Store({
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "#fafafa",
    setHorizontalLayout: false, // Horizontal layout

    clients: null,
    cars: null,
    departments: null,
    categories: null,
    projects: null,
    workers: null,
    work_list: null,
    suppliers: null,
    auto_parts: null,
    deliveries: null,
  },
  mutations: {

    setClients: (state, payload) => {
      state.clients = payload;
    },

    setCars: (state, payload) => {
      state.cars = payload
    },

    setDepartments: (state, payload) => {
      state.departments = payload
    },

    setCategories: (state, payload) => {
      state.categories = payload
    },

    setProjects: (state, payload) => {
      state.projects = payload
    },

    setWorkers: (state, payload) => {
      state.workers = payload
    },

    setWorkList: (state, payload) => {
      state.work_list = payload
    },

    setSuppliers: (state, payload) => {
      state.suppliers = payload
    },

    setAutoParts: (state, payload) => {
      state.auto_parts = payload
    },

    setDeliveries: (state, payload) => {
      state.deliveries = payload
    },

    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },

    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
  },
  actions: {},
  getters: {},
  plugins: [vuexLocal.plugin]
});
